import { all } from 'redux-saga/effects';

import watchFormSubmitSaga from './onSubmitActions.saga';
import {
  watchCancel,
  watchSaveChanges,
  watchSaveProfileFail,
} from '../modules/profile.save.module';
import { watchFetchUser } from '../modules/user.module.ts';
import { watchPageDataLoad, watchShowErrorPage } from '../modules/page.module.js';
import { watchChangeLocale } from '../modules/locale.module';
import { watchCreateMachine } from '../modules/newMachine.create.module';
import { watchReset as watchMachinePageFormReset } from '../modules/machine.page.form.module';
import { watchSendNotification } from '../modules/notifications.module';
import { watchFetchEvents } from '../modules/dashboard.fetch.events.module';
import {
  watchHideEvent,
  watchInitHiddenEvents,
  watchRevealEvent,
} from '../modules/maintenance.columns.event.hide.module';
import { watchResolveActivity } from '../modules/maintenance.columns.activity.resolve.module';
import { watchForSearchUsers } from '../modules/users.search.module';
import { watchForSearchUsersByOrganization } from '../modules/users.organization.search.module';
import { watchAssignUser } from '../modules/maintenance.columns.activity.assign.module';
import { watchMoveActivity } from '../modules/maintenance.columns.activity.move.module';
import { watchUpdateActivity } from '../modules/maintenance.columns.activity.update.module';
import { watchCreateActivity } from '../modules/maintenance.columns.activity.create.module';
import { watchFetchMaintenanceActivities } from '../modules/maintenance.fetch.activities.module';
import { watchFetchEventsByMachine } from '../modules/machine.fetch.events.module';
import { watchFetchMachine, watchFetchMachineData } from '../modules/machine.fetch.module';
import { watchRemoveReport } from '../modules/reports.remove.module';
import { watchFetchReports } from '../modules/reports.fetch.module';
import { watchSearch } from '../modules/search.module';
import { watchFetchInventory } from '../modules/inventory.fetch.module';
import { watchSubmitComment } from '../modules/dashboard.event.comment.submit.module';
import {
  watchEventFilters,
  watchEventFiltersDebounce,
} from '../modules/dashboard.events.filter.module';
import { watchChangeEventFilterQuery } from '../modules/dashboard.events.filter.query.module';
import { watchChangeTopBarQuery } from '../modules/search.topBar.module';
import { watchFetchOee } from '../modules/dashboard.oee.fetch.module';
import {
  watchAutoFetchMaintenanceEvents,
  watchFetchMaintenanceEvents,
} from '../modules/maintenance.fetch.events.module';
import { watchFetchOU } from '../modules/ou.fetch.module';
import { watchFetchLocationById } from '../modules/location.fetch.module';
import { watchLoadMoreEvents } from '../modules/dashboard.loadMode.events.module';
import { watchReceiveMachine } from '../modules/inventory.machine.receive.module.js';
import { watchFetchBatches } from '../modules/batch.fetch.module';
import { watchFetchBatchDetails } from '../modules/batch.details.fetch.module';
import { watchFetchEventsSummaryByMachine } from '../modules/machine.fetch.summary.module';
import { watchFetchFetchPayloadByEvent } from '../modules/dashboard.event.fetch.payload.module';
import { watchLoadMoreMaintenanceEvents } from '../modules/maintenance.loadMore.events.module';
import { watchFetchMaintenanceCounts } from '../modules/maintenance.fetch.eventsCount.module';
import { watchChangeMaintenanceFilter } from '../modules/maintenance.columns.module';
import { watchFetchEventsSummaryGroup } from '../modules/machine.fetch.summaryGroup.module';
import { watchLoadMoreSummaryGroupEvents } from '../modules/machine.fetch.summaryGroup.loadMore.module';
import { watchSwitchSummaryGroupMode } from '../modules/machine.summaryGroup.switch.mode.module';
import { watchFetchEventsAmountSummaryGroup } from '../modules/machine.fetch.summaryGroup.eventsCount.module';
import { watchFetchCluster } from '../modules/dashboard.event.fetch.cluster.module';
import { watchFetchClustersSummaryByMachine } from '../modules/machine.fetch.clusters.summary.module';
import { watchFetchClustersGroupByMachine } from '../modules/machine.fetch.clusters.group.module';
import { watchFetchClusterOfClusterGroupByMachine } from '../modules/machine.fetch.cluster.module';
import {
  watchClusterFiltersUpdates,
  watchGroupFiltersUpdates,
} from '../modules/machine.summary.sort.filter.module';
import { watchFetchAnomalySaga } from '../modules/anomaly.fetch.module';
import { watchBatchFiltersUpdates } from '../modules/batch.sort.filter.module';
import { watchAddNewAssistant } from '../modules/machine.newAssistant.add.module';
import { watchFetchLocationPickerById } from '../modules/locationPicker.fetch.module';
import { watchSelectLocation } from '../modules/locationPicker.select.module';
import { watchChangeLocation } from '../modules/profile.location.change.module';
import { watchInitLocationPicker } from '../modules/locationPicker.initialize.module';
import { watchRemoveAssistant } from '../modules/machine.assistant.remove.module';
import { watchSelectPreviewItem } from '../modules/inventory.preview.module';
import { watchFetchPreviewItem } from '../modules/inventory.preview.fetch.module';
import { watchCreateDatatron } from '../modules/newDatatron.create.module';
import { watchCreateCalendar } from '../modules/calendars.create.module';
import { watchUpdateCalendar } from '../modules/calendars.update.module';
import { watchDeleteCalendar } from '../modules/calendars.delete.module';
import {
  watchFetchMachineCalendars,
  watchMachineCalendarsData,
} from '../modules/calendars.fetch.module';
import {
  watchFetchCalendarEvents,
  watchReloadCalendarEvents,
} from '../modules/calendars.fetch.calendarEvents.module';
import { watchCreateCalendarEvent } from '../modules/calendars.create.event.module';
import {
  watchFailedUpdateCalendarEventAction,
  watchSuccessUpdateCalendarEventAction,
  watchUpdateCalendarEvent,
} from '../modules/calendars.update.event.module';
import { watchDeleteCalendarEvent } from '../modules/calendars.delete.event.module';
import { watchSetMachineCalendars } from '../modules/machine.calendars.change.module';
import { watchFetchMachineCalendarsEvents } from '../modules/machine.calendars.fetch.events.module';
import { watchUpdateMachine } from '../modules/machine.update.module';
import { watchDeleteMachinePhoto } from '../modules/machine.delete.photo.module';
import { watchFetchDatatron } from '../modules/datatron.fetch.module';
import { watchReceiveDatatron } from '../modules/inventory.datatron.receive.module';
import { watchRemoveDatatronAssistant } from '../modules/datatron.assistant.remove.module';
import { watchAddNewDatatronAssistant } from '../modules/datatron.newAssistant.add.module';
import { watchFetchDeviceClasses } from '../modules/datatron.deviceClasses.fetch.module';
import { watchFetchDeviceTypes } from '../modules/datatron.deviceTypes.fetch.module';
import { watchAddDevice } from '../modules/datatron.devices.add.module';
import { watchFetchDevices } from '../modules/datatron.devices.fetch.module';
import { watchFetchDataPoints } from '../modules/datatron.devices.dataPoints.fetch.module';
import {
  watchCreateChildDataPoint,
  watchCreateDataPoint,
} from '../modules/datatron.devices.dataPoints.create.module';
import { watchUpdateDataPoint } from '../modules/datatron.devices.dataPoints.update.module';
import { watchUpdateBulkDataPoints } from '../modules/datatron.devices.dataPoints.update.bulk.module';
import { watchUpdateBulkExcelDataPoints } from '../modules/datatron.devices.dataPoints.update.bulk.excel.module.ts';
import { watchArchiveDataPoint } from '../modules/datatron.devices.dataPoints.archive.module';
import { watchFetchConfigRepoExplorerEntries } from '../modules/configRepoExplorer.fetch.module';
import { watchSubscribeMachine } from '../modules/machine.dataPoints.subscribe.module';
import { watchFetchMachineDataPoints } from '../modules/machine.dataPoints.fetch.module';
import { watchUpdateMachineSubscription } from '../modules/machine.dataPoints.update.module';
import { watchUnsubscribeMachine } from '../modules/machine.dataPoints.unsubscribe.module';
import { watchHighlightDataPoint } from '../modules/datatron.devices.dataPoints.highlight.module';
import { watchDeleteDatatronPhoto } from '../modules/datatron.delete.photo.module';
import { watchUpdateDevice } from '../modules/datatron.devices.update.module';
import { watchArchiveDevice } from '../modules/datatron.devices.archive.module';
import { watchCloneCalendar } from '../modules/calendars.clone.module';
import { watchCheckReFetchMachine } from '../modules/machine.refetch.check.module';
import { watchCheckReFetchDatatron } from '../modules/datatron.refetch.check.module';
import { watchUpdateDatatron } from '../modules/datatron.update.module';
import { watchVerifyDownload } from '../modules/export.widget.verification.module';
import { watchSaveLocationPickerValueToPathButton } from '../modules/pathButton.tempLocationPath.write.module';
import { watchResetDatatronPageForm } from '../modules/datatron.page.form.module';
import { watchResetProfilePageForm } from '../modules/profile.page.form.module';
import { watchFetchAdminLocations } from '../modules/admin.locations.fetch.module';
import { watchFetchAdminLocation } from '../modules/admin.location.fetch.module';
import { watchAdminRemoveLocation } from '../modules/admin.location.remove.module';
import { watchResetAdminLocationForm } from '../modules/admin.location.form.module';
import { watchAdminCreateLocation } from '../modules/admin.location.create.module';
import { watchAdminUpdateLocation } from '../modules/admin.location.update.module';
import {
  watchAdminLocationsFiltersChange,
  watchAdminResetLocationsFilters,
} from '../modules/admin.locations.sort.filter.module';
import { watchAdminFetchUsers } from '../modules/admin.users.fetch.module';
import { watchAdminResetUserForm } from '../modules/admin.user.form.module';
import {
  watchAdminResetUsersFilters,
  watchAdminUsersFiltersChange,
} from '../modules/admin.users.sort.filter.module';
import { watchAdminCreateUser } from '../modules/admin.user.create.module';
import { watchAdminFetchUser } from '../modules/admin.user.fetch.module';
import { watchAdminUpdateUser } from '../modules/admin.user.update.module';
import { watchAdminUpdateUserRoles } from '../modules/admin.user.update.roles.module';
import { watchAdminFetchAllOrganizations } from '../modules/admin.organizations.all.fetch.module';
import { watchAdminFetchOrganizations } from '../modules/admin.organizations.fetch.module';
import { watchAdminCreateOrganization } from '../modules/admin.organization.create.module';
import { watchAdminFetchOrganization } from '../modules/admin.organization.fetch.module';
import { watchAdminUpdateOrganization } from '../modules/admin.organization.update.module';
import { watchAdminRemoveOrganization } from '../modules/admin.organization.remove.module';
import {
  watchAdminOrganizationsFiltersChange,
  watchAdminResetOrganizationFiltersSaga,
} from '../modules/admin.organizations.sort.filter.module';
import { watchAdminFetchAccounts } from '../modules/admin.accounts.fetch.module';
import { watchAdminFetchAccount } from '../modules/admin.account.fetch.module';
import { watchAdminCreateAccount } from '../modules/admin.account.create.module';
import { watchAdminUpdateAccount } from '../modules/admin.account.update.module';
import { watchAdminFetchRoles } from '../modules/admin.roles.fetch.module';
import { watchAdminFetchRole } from '../modules/admin.role.fetch.module';
import { watchAdminUpdateRole } from '../modules/admin.role.update.module';
import { watchDuplicateReport } from '../modules/report.duplicate.module';
import { watchFetchReportsTabs } from '../modules/reportsTabs.fetch.module';
import { watchAddReportsTab } from '../modules/reportsTabs.add.module';
import { watchEditReportsTab } from '../modules/reportsTabs.edit.module';
import { watchRemoveReportsTab } from '../modules/reportsTabs.remove.module';
import { watchFetchReportsEntry } from '../modules/reports.entry.fetch.module';
import { watchUpdateReport } from '../modules/report.update.module';
import { watchCreateReport } from '../modules/report.create.module';
import { watchSetReportControls } from '../modules/report.controls.module';
import { watchUpdateLegendDataPoint } from '../modules/report.legend.dataPoint.update.module';
import { watchRemoveLegendDataPoint } from '../modules/report.legend.dataPoint.remove.module';
import { watchCacheFormField, watchRestoreFormField } from '../modules/common.form.module';
import {
  watchCancelDataPointsSelection,
  watchSaveSelectedDataPoint,
} from '../modules/report.form.dataPoints.module';
import { watchFetchMachineOee } from '../modules/machine.oee.fetch.module';
import { watchFetchMachineTools } from '../modules/machine.tools.fetch.module';
import { watchFetchTools } from '../modules/tools.fetch.module';
import { watchCreateTool } from '../modules/tools.create.module';
import { watchEditTool } from '../modules/tools.edit.module';
import { watchFetchAllMachines } from '../modules/machines.fetch.module';
import { watchToolsFiltersChange } from '../modules/tools.filter.module';
import { watchFetchAdminLocationsPagination } from '../modules/admin.locations.pagination.fetch.module';
import { watchFetchAdminOrganizationsPagination } from '../modules/admin.organizations.pagination.fetch.module';
import { watchFetchDataPointMetric } from '../modules/dataPoint.metric.fetch.module';
import { watchDownloadDevice } from '../modules/datatron.devices.download.module';
import { watchFetchExperiments } from '../modules/experiments.fetch.module';
import { watchFetchExperimentsPagination } from '../modules/experiments.fetch.pagination.module';
import { watchCreateExperiment } from '../modules/experiments.create.module';
import { watchUpdateExperiment } from '../modules/experiments.update.module';
import { watchExperimentsFiltersChange } from '../modules/experiments.filter.module';
import { watchArchiveExperiment } from '../modules/experiments.archive.module';
import { watchFetchMeasurements } from '../modules/measurements.fetch.module';
import { watchFetchMeasurementsPagination } from '../modules/measurements.fetch.pagination.module';
import { watchMeasurementsFiltersChange } from '../modules/measurements.filter.module';
import { watchArchiveMeasurement } from '../modules/measurements.archive.module';
import { watchMergeMeasurement } from '../modules/measurements.merge.module';
import { watchFetchAllUsers } from '../modules/users.fetch.module';
import { watchSyncMachineToAltasigma } from '../modules/machine.syncToAltasigma.module';
import { watchFetchMeasurementsByExperiment } from '../modules/experimentsPage.measurementsByExperiment.fetch.module';
import { watchImportDevice } from '../modules/datatron.devices.import.module';
import { watchSetLocationFilter } from '../modules/location.filter.module';
import { wachCommandHistory } from '../modules/datatron.devices.command.module';
import { watchQuickCommand } from '../modules/datatron.devices.quickCommands.module';

export default function* root() {
  yield all([
    watchFormSubmitSaga(),
    watchFetchUser(),
    watchCancel(),
    watchSaveChanges(),
    watchPageDataLoad(),
    watchShowErrorPage(),
    watchChangeLocale(),
    watchCreateMachine(),
    watchMachinePageFormReset(),
    watchSendNotification(),
    watchHideEvent(),
    watchInitHiddenEvents(),
    watchRevealEvent(),
    watchMoveActivity(),
    watchUpdateActivity(),
    watchCreateActivity(),
    watchFetchMaintenanceActivities(),
    watchResolveActivity(),
    watchForSearchUsers(),
    watchForSearchUsersByOrganization(),
    watchAssignUser(),
    watchFetchEventsByMachine(),
    watchFetchMachine(),
    watchFetchMachineData(),
    watchFetchInventory(),
    watchRemoveReport(),
    watchFetchReports(),
    watchSearch(),
    watchEventFilters(),
    watchEventFiltersDebounce(),
    watchChangeEventFilterQuery(),
    watchSubmitComment(),
    watchChangeTopBarQuery(),
    watchFetchOee(),
    watchFetchMaintenanceEvents(),
    watchFetchOU(),
    watchFetchLocationById(),
    watchLoadMoreEvents(),
    watchFetchEvents(),
    watchReceiveMachine(),
    watchFetchBatches(),
    watchFetchBatchDetails(),
    watchFetchEventsSummaryByMachine(),
    watchFetchFetchPayloadByEvent(),
    watchLoadMoreMaintenanceEvents(),
    watchFetchMaintenanceCounts(),
    watchChangeMaintenanceFilter(),
    watchFetchEventsSummaryGroup(),
    watchLoadMoreSummaryGroupEvents(),
    watchSwitchSummaryGroupMode(),
    watchFetchEventsAmountSummaryGroup(),
    watchFetchCluster(),
    watchFetchClustersSummaryByMachine(),
    watchFetchClustersGroupByMachine(),
    watchFetchClusterOfClusterGroupByMachine(),
    watchGroupFiltersUpdates(),
    watchClusterFiltersUpdates(),
    watchFetchAnomalySaga(),
    watchAutoFetchMaintenanceEvents(),
    watchBatchFiltersUpdates(),
    watchAddNewAssistant(),
    watchFetchLocationPickerById(),
    watchSelectLocation(),
    watchChangeLocation(),
    watchInitLocationPicker(),
    watchRemoveAssistant(),
    watchFetchPreviewItem(),
    watchSelectPreviewItem(),
    watchCreateDatatron(),
    watchCreateCalendar(),
    watchUpdateCalendar(),
    watchFetchMachineCalendars(),
    watchMachineCalendarsData(),
    watchFetchCalendarEvents(),
    watchCreateCalendarEvent(),
    watchUpdateCalendarEvent(),
    watchSuccessUpdateCalendarEventAction(),
    watchFailedUpdateCalendarEventAction(),
    watchDeleteCalendarEvent(),
    watchSetMachineCalendars(),
    watchDeleteCalendar(),
    watchFetchMachineCalendarsEvents(),
    watchReloadCalendarEvents(),
    watchUpdateMachine(),
    watchDeleteMachinePhoto(),
    watchFetchDatatron(),
    watchReceiveDatatron(),
    watchRemoveDatatronAssistant(),
    watchAddNewDatatronAssistant(),
    watchFetchDeviceClasses(),
    watchFetchDeviceTypes(),
    watchAddDevice(),
    watchFetchDevices(),
    watchFetchDataPoints(),
    watchCreateDataPoint(),
    watchUpdateDataPoint(),
    watchUpdateBulkDataPoints(),
    watchUpdateBulkExcelDataPoints(),
    watchArchiveDataPoint(),
    watchFetchConfigRepoExplorerEntries(),
    watchSubscribeMachine(),
    watchFetchMachineDataPoints(),
    watchUpdateMachineSubscription(),
    watchUnsubscribeMachine(),
    watchHighlightDataPoint(),
    watchDeleteDatatronPhoto(),
    watchUpdateDevice(),
    watchArchiveDevice(),
    watchImportDevice(),
    wachCommandHistory(),
    watchSaveProfileFail(),
    watchCloneCalendar(),
    watchCheckReFetchMachine(),
    watchUpdateDatatron(),
    watchCheckReFetchDatatron(),
    watchVerifyDownload(),
    watchSaveLocationPickerValueToPathButton(),
    watchResetDatatronPageForm(),
    watchResetProfilePageForm(),
    watchFetchAdminLocations(),
    watchFetchAdminLocation(),
    watchAdminRemoveLocation(),
    watchResetAdminLocationForm(),
    watchAdminCreateLocation(),
    watchAdminUpdateLocation(),
    watchAdminLocationsFiltersChange(),
    watchAdminFetchUsers(),
    watchAdminResetUserForm(),
    watchAdminUsersFiltersChange(),
    watchAdminCreateUser(),
    watchAdminFetchUser(),
    watchAdminUpdateUser(),
    watchAdminUpdateUserRoles(),
    watchAdminFetchAllOrganizations(),
    watchAdminFetchOrganizations(),
    watchAdminCreateOrganization(),
    watchAdminFetchOrganization(),
    watchAdminUpdateOrganization(),
    watchAdminRemoveOrganization(),
    watchAdminOrganizationsFiltersChange(),
    watchAdminFetchAccounts(),
    watchAdminFetchAccount(),
    watchAdminCreateAccount(),
    watchAdminUpdateAccount(),
    watchAdminFetchRoles(),
    watchAdminFetchRole(),
    watchAdminUpdateRole(),
    watchAdminResetOrganizationFiltersSaga(),
    watchAdminResetLocationsFilters(),
    watchAdminResetUsersFilters(),
    watchDuplicateReport(),
    watchFetchReportsTabs(),
    watchAddReportsTab(),
    watchEditReportsTab(),
    watchRemoveReportsTab(),
    watchFetchReportsEntry(),
    watchUpdateReport(),
    watchCreateReport(),
    watchSetReportControls(),
    watchUpdateLegendDataPoint(),
    watchRemoveLegendDataPoint(),
    watchCacheFormField(),
    watchRestoreFormField(),
    watchSaveSelectedDataPoint(),
    watchCancelDataPointsSelection(),
    watchFetchMachineOee(),
    watchFetchMachineTools(),
    watchFetchTools(),
    watchCreateTool(),
    watchEditTool(),
    watchFetchAllMachines(),
    watchToolsFiltersChange(),
    watchFetchAdminLocationsPagination(),
    watchFetchAdminOrganizationsPagination(),
    watchFetchDataPointMetric(),
    watchDownloadDevice(),
    watchFetchExperiments(),
    watchFetchExperimentsPagination(),
    watchCreateExperiment(),
    watchUpdateExperiment(),
    watchArchiveExperiment(),
    watchExperimentsFiltersChange(),
    watchFetchAllUsers(),
    watchFetchMeasurements(),
    watchFetchMeasurementsPagination(),
    watchArchiveMeasurement(),
    watchMergeMeasurement(),
    watchMeasurementsFiltersChange(),
    watchSyncMachineToAltasigma(),
    watchFetchMeasurementsByExperiment(),
    watchSetLocationFilter(),
    watchQuickCommand(),
    watchCreateChildDataPoint(),
  ]);
}
